<template>
  <!-- Edit Topic -->
  <Dialog
    v-model:visible="showDialog"
    
    header="Edit Topic"
    :modal="true"
    class="p-fluid dialogModal"
    @hide="closeDialog()"
  >
  <!-- Header -->
    <div class="field" style="margin: 20px; margin-bottom: 1rem; display: flex; align-items: center; gap: 2rem;">
      <!-- name -->
      <div style="width: 40%;">
        <label for="name">Name</label>
        <InputText
          id="name"
          v-model.trim="form.name"
          @blur="v$.form.name.$touch"
          required="true"
          autofocus
          :class="{ 'p-invalid': !form.name }"
        />
        <small class="p-error" v-if="!form.name">Name is required.</small>
      </div>

      <!-- Topic Years -->
      <div style="width: 20%;">
        <label for="topicYears">Topic Years</label>
        
        <div style="display: flex; gap: 1rem;">
          <div style="position: relative;">
            <i class="pi pi-angle-down" style="font-size: 1.5rem; position:absolute; right: 5%; top:12%; z-index: 2;"></i>
            <i v-if="startYear" @click="startYear=null"  class="pi pi-times" style="font-size: 1rem; position:absolute; right: 25%; top:30%; z-index: 2; cursor: pointer;"></i>
            <Calendar v-model="startYear" view="year" dateFormat="yy"/>
          </div>

          <div style="position: relative;">
            <i class="pi pi-angle-down" style="font-size: 1.5rem; position:absolute; right: 5%; top:12%; z-index: 2;"></i>
            <i v-if="endYear" @click="endYear=null"  class="pi pi-times" style="font-size: 1rem; position:absolute; right: 25%; top:30%; z-index: 2; cursor: pointer;"></i>
            <Calendar v-model="endYear" view="year" dateFormat="yy"/>
          </div>
          
        </div>
      </div>

      <!-- CRM Unit Desc -->
      <div style="width: 20%;">
        <label for="CRMUNITDESC">CRM Unit Desc</label>
        <div>
          <InputText 
            id="CRMUNITDESC"
            v-model.trim="form.CRMUnitDesc"
          />
        </div>
      </div>

      <!-- CRM Unit Init Number -->
      <div style="width: 20%;">
        <label for="CRMUnitInitNumber">CRM Unit Init Number</label>
        <div>
          <InputText 
            id="CRMUnitInitNumber"
            v-model.trim="form.CRMUnitInitNumber"
          />
        </div>
      </div>

      <!-- Platform -->
      <div style="width: 10%;">
        <label for=""></label>
        <div style="display: flex; align-items: center; gap: 1rem;">
          <label for="CRMUnitInitNumber">Platform</label>
          <InputSwitch v-model="form.isPlatformTopic" />
        </div>
      </div>
    </div>
    
  <!-- notes -->
    <!-- <div class="field my-3">
      <Panel header="Notes" :toggleable="true" :collapsed="true">
        <QuillEditor
          theme="snow"
          toolbar="full"
          v-model:content="form.notes"
          @blur="v$.form.notes.$touch"
          contentType="html"
          :style="{ 'min-height': '500px' }"
        />
      </Panel>
    </div> -->
    <!-- segmentations -->
    <Divider align="left">
      <div class="inline-flex align-items-center">
        <span class="p-tag">Segmentations</span>
      </div>
    </Divider>

    <!-- <SegmentationsRegionList :topicId="selectedElement.topicId" /> -->
    <!-- <SegmentationsList :topicId="selectedElement.topicId" /> -->
    <SegmentationTestListVue 
      :topicId="selectedElement.topicId" 
    />
    <!-- Scenarios -->
    <Divider align="left">
      <div class="inline-flex align-items-center">
        <span class="p-tag">Scenarios</span>
      </div>
    </Divider>
    <ScenariosList :topicId="selectedElement.topicId" />

    <!-- footer  -->
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog()"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :loading="editIsLoading"
        :disabled="this.v$.form.$invalid || editIsLoading"
        @click="save()"
      />
    </template>
  </Dialog>
</template>

<script>
// import SegmentationsList from './SegmentationsList.vue';
// import SegmentationsRegionList from './SegmentationsRegionList.vue';
import SegmentationTestListVue from './SegmentationTestList.vue';
import ScenariosList from './ScenariosList.vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
// import Panel from 'primevue/panel';
import Divider from 'primevue/divider';
// import { QuillEditor } from '@vueup/vue-quill';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'EditTopic',
  components: {
    Button,
    InputText,
    // QuillEditor,
    Dialog,
    InputSwitch,
    Calendar,
    // SegmentationsList,
    // SegmentationsRegionList,
    SegmentationTestListVue,
    ScenariosList,
    // Panel,
    Divider,
  },
  props: ['selectedElement'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: true,
      editIsLoading: false,
      showDialog: false,
      startYear:null,
      endYear:null,
      form: {
        name: null,
        
        CRMUnitDesc:null,
        CRMUnitInitNumber:null,
        isPlatformTopic:false,
        // notes: null,
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        // notes: { required },
      },
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.form =  {
        name: null,
        
        CRMUnitDesc:null,
        CRMUnitInitNumber:null,
        isPlatformTopic:false,
        // notes: null,
      },
      this.startYear = null
      this.endYear = null 

      this.$emit('closeDialog', 'edit');
    },
    async save() {
      try {
        if (!this.v$.form.$invalid) {
          this.editIsLoading = true;
          const topic = {
            ...this.selectedElement,
            name: this.form.name,
            startYear: (this.startYear)?this.startYear.getFullYear():this.form.startYear, 
            endYear: (this.endYear)?this.endYear.getFullYear():this.form.endYear,
            // notes: this.form.notes,
          };
          console.log(topic);
          
          await this.$store.dispatch('modifyTopic', topic);
          this.editIsLoading = false;
          toast.success('Successfully updated.');
          this.closeDialog();
        } else {
          toast.error('Wrong topic data.');
        }
      } catch (error) {
        this.editIsLoading = false;
        toast.error(error.message);
      }
    },
  },
  watch: {
    selectedElement(newVal) {
      if (newVal) {
        this.showDialog = true;
        this.form = Object.assign({}, this.selectedElement);

        // this.startYear = (this.form.startYear || this.form.startYear !== 0 )?this.form.startYear: null 
        console.log(this.startYear);
        if(this.form.startYear || this.form.startYear !== 0){
          const dateStartYear = new Date(parseInt(this.form.startYear), 0, 1);
          this.startYear = dateStartYear
        }

        // this.endYear = (this.form.endYear || this.form.endYear !== 0  )?this.form.endYear :null 
        console.log(this.endYear);
        if(this.form.endYear || this.form.endYear !== 0){
          const dateEndYear = new Date(parseInt(this.form.endYear), 0, 1);
          this.endYear = dateEndYear
        }
        
        // console.log(this.form);
        
      }
    },
  },
};
</script>

<style>
.dialogModal{
    width: 100%;
  }
@media (  min-width:1024px ) {
  .dialogModal{
    width: 70%;
  }
}
@media (  max-width:1024px ) {
  .dialogModal{
    width: 90%;
  }
}

</style>
