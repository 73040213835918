<template>
  <Button
    type="button"
    icon="pi pi-plus"
    label="Add"
    class="p-button-outlined"
    @click="openDialog()"
  />

  <!-- Create Topic -->
  <Dialog
    v-model:visible="showDialog"
    :style="{ width: '90%' }"
    header="Create Topic"
    :modal="true"
    class="p-fluid"
    :maximizable="true"
  >
    <div class="field">
      <label for="name">Name</label>
      <InputText
        id="name"
        v-model.trim="form.name"
        @blur="v$.form.name.$touch"
        required="true"
        autofocus
        :class="{ 'p-invalid': !form.name }"
      />
      <small class="p-error" v-if="!form.name">Name is required.</small>
    </div>

    <!-- <div class="field my-3">
      <Panel header="Notes" :toggleable="true" :collapsed="true">
        <QuillEditor
          theme="snow"
          toolbar="full"
          v-model:content="form.notes"
          @blur="v$.form.notes.$touch"
          :content="form.notes"
          contentType="html"
          :style="{ 'min-height': '500px' }"
        />
      </Panel>
    </div> -->

    <!-- <div class="field">
      <label for="name">Segmentation By Region</label>
      <InputText
        id="name"
        v-model.trim="form.name"
        @blur="v$.form.name.$touch"
        required="true"
        autofocus
        :class="{ 'p-invalid': !form.name }"
      />
      <small class="p-error" v-if="!form.name">Segmentation By Region is required.</small>
    </div>
     -->
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog()"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :loading="createIsLoading"
        :disabled="this.v$.form.$invalid || createIsLoading"
        @click="save()"
      />
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
// import Panel from 'primevue/panel';
// import { QuillEditor } from '@vueup/vue-quill';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'CreateTopic',
  emits:['setCreateSelectedEditRow'],
  components: {
    Button,
    InputText,
    // QuillEditor,
    Dialog,
    // Panel,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      createIsLoading: false,
      showDialog: false,
      form: {
        name: null,
        notes: null,
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        // notes: { required },
      },
    };
  },
  computed: {},
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.form = {
        name: null,
        notes: null,
      };
    },
    async save() {
      try {
        if (!this.v$.form.$invalid) {
          this.createIsLoading = true;
          const response =  await this.$store.dispatch('addNewTopic', this.form);
          this.$emit('setCreateSelectedEditRow', response.data) 
          this.createIsLoading = false;
          toast.success('Successfully created.');
          this.closeDialog();
        } else {
          toast.error('Wrong topic data.');
        }
      } catch (error) {
        this.createIsLoading = false;
        toast.error(error.message);
      }
    },
  },
};
</script>
